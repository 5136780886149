// manage the context providers in here, this function will just return the context providers which wraps app.js
// global state is the only provider and consumer most likely in here
import React from 'react';
import GlobalStateProvider from "./contexts/global/GlobalStateProvider";
import GlobalStateContext from "./contexts/global/GlobalStateContext";

// if you want you can pass the globalContext right into your provider as a prop. It will then be available
export default props => (
    <GlobalStateProvider>
        <GlobalStateContext.Consumer>
            {globalContext => (
                props.children
            )}
        </GlobalStateContext.Consumer>
    </GlobalStateProvider>
)