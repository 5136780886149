import React, {useMemo,useEffect,useState} from 'react';
import {useFieldArray} from 'react-hook-form'
import {useDropzone} from 'react-dropzone';
import {getFullImage,setWidth,setHeight} from '../../lib/stitch'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const container = {
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  export default function ImageDropZone(props) {
    const { control, register, setValue, disabled, files, setShowImageModal, setImage } = props
    const { fields, append} = useFieldArray({control, name: 'files'})
    const [loading, setLoading] = useState(false)

    const img = {
        display: 'block',
        width: setWidth(),
        height: setHeight(),
        cursor:'pointer',
    };

    const thumb = {
        display: 'inline',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 'auto',
        height: 'auto',
        padding: 4,
        boxSizing: 'border-box'
      };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({
        disabled: disabled,
        accept: 'image/*',
        multiple: false,
        minSize: 0,
        maxSize: 5242880,
        onDrop: async (acceptedFiles) => {
            var newFiles = acceptedFiles.map((file) => Object.assign(file, {
                preview: URL.createObjectURL(file),
            }))


            newFiles[0].source = await toBase64(acceptedFiles[0])
            newFiles[0].buffer = new Buffer(newFiles[0].source, 'base64')
            append(newFiles)
            setValue(`files[${fields.length}].file`, newFiles)
          }
    });

    const setThumbs = () => {
        if(disabled){
            return files.map((file, index) => {
                return (
                    <div style={thumb} key={file.name}>
                        <div style={thumbInner}>
                            <img
                                src={file.blob}
                                style={img}
                                alt={'test'}
                                onClick={async () => {
                                  document.body.style.cursor='wait'
                                  setTimeout(async () => {
                                    var image = await getFullImage(file.name)
                                    setImage(image)
                                    setShowImageModal(true)
                                    document.body.style.cursor='default'
                                  }, 300)
                                }}
                            />
                        </div>
                    </div>
                )
            })
        } else {
            return fields.map((file, index) => {
                return (
                    <div style={thumb} key={file.id}>
                        <div className="justify-context-start" style={thumbInner}>
                            <img
                                ref={register()}
                                name={`files[${index}].file`}
                                src={file.source}
                                style={img}
                                alt={file.name}
                            />
                        </div>
                    </div>
                )
            })
        }
    }

    const thumbs = setThumbs()

    useEffect(() => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fields.forEach(file => URL.revokeObjectURL(file.preview));
    }, [fields]);
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject
    ])
  
    return (
      <div style={container}>
        <div {...getRootProps({style})} style={{maxHeight:250}}>
          <input {...getInputProps()} />
          {!disabled && <p>Add a photo!</p>}
          <div className="d-flex flex-wrap" style={{maxHeight:250}}>
            {thumbs}
          </div>
        </div>
      </div>
    );
  }