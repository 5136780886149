import React from 'react'
import {Modal,Form,Button,Col,Spinner,Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import IngredientLines from './IngredientLines'
import Directions from './Directions'
import ImageDropZone from './ImageDropZone'

export default function AddRecipeModal(props){
    const {showRecipeModal, loading, userData} = props.globalContext.state
    const {setShowRecipeModal, submitRecipe, setLoading} = props.globalContext

    const {control, register, handleSubmit,watch,errors, clearError, setValue} = useForm({
        defaultValues: {
          ingredients: [{name: '', quantity: '', unit: ''}],
          directions: [{step: '1', direction: ''}],
          files: []
        }
    })

    const handleClose = () => {
        if(loading) setLoading(false)
        clearError()
        setShowRecipeModal(false)
    }

    return (
        <Modal show={showRecipeModal} onHide={handleClose} size="lg">
            <Modal.Header style={{backgroundColor:'#564256',color:'white'}} closeButton>
                <Modal.Title>Add New Recipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(submitRecipe)}>
                    <Row>
                        <Col>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label>Recipe Name</Form.Label>
                                <Form.Control 
                                ref={register({
                                    required: 'Please enter a recipe name',
                                    pattern: {
                                    value: /[A-Z]/gi,
                                    message: "Name must only contain letters"
                                    }
                                })} 
                                name="recipeName" 
                                size="sm" 
                                type="text" 
                                placeholder="Bread Pudding"
                                className={errors.recipeName ? 'is-invalid' : ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Label></Form.Label>
                            <Form.Group id="formGridCheckbox" className="mt-1">
                                <Form.Check ref={register()}  name="familyRecipe" className="mt-1" type="checkbox" label="Family Recipe" />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                <Form.Row>
                    <Form.Group as={Col} col-6 controlId="formGroupPassword">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control ref={register({
                        required: "Please enter the author's name",
                        pattern: {
                            value: /[A-Z]/gi,
                            message: "Name must only contain letters"
                        }
                        })} name="recipeAuthor" defaultValue={userData && userData.customData.name} size="sm" type="text" className={errors.recipeAuthor ? 'is-invalid' : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGroupPassword">
                        <Form.Label>Given to By</Form.Label>
                        <Form.Control ref={register} name="recipeOwner" size="sm" type="text" className={errors.recipeOwner ? 'is-invalid' : ''}/>
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Recipe Description</Form.Label>
                    <Form.Control ref={register({
                    required: 'Please enter a description'
                    })} name="recipeDescription" as="textarea" rows="3" className={errors.recipeDescription ? 'is-invalid' : ''}/>
                </Form.Group>
                <ImageDropZone control={control} register={register} handleSubmit={handleSubmit} watch={watch} errors={errors} setValue={setValue}/>
                <IngredientLines control={control} register={register} handleSubmit={handleSubmit} watch={watch} errors={errors}/>
                <Directions control={control} register={register} handleSubmit={handleSubmit} watch={watch} errors={errors}/>
                <Modal.Footer>
                    {!loading ? 
                        <React.Fragment>
                            <button type="button" className="btn btn btn-outline-danger" type='submit'onClick={handleClose}>Cancel</button>
                            <button type="button" className="btn btn btn-outline-success" type='submit'>Add Recipe</button>
                        </React.Fragment>
                    : 
                        <Button variant='outline-success'>
                            <Spinner size="sm" animation="border" variant="success"/>
                        </Button>
                    }
                </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}