import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import errorHandler from '../../lib/ErrorHandler'
import queryString from 'query-string'
import {BookOpen} from 'react-feather'
import {resetPassword} from '../../lib/stitch'
import {Spinner} from 'react-bootstrap'

export default function ResetMyPassword(){
    const [errors, setErrors] = useState(false)
    const [resetComplete, setResetComplete] = useState(false)
    const [loading, setLoading] = useState(false)

    const getConfirmationId = () => {
        return queryString.parse(window.location.search)
    }

    const resetMyPassword = async () => {
        try {
            setLoading(true)
            if(errors) setErrors(false)
            var password = document.getElementById('password').value
            var passwordConfirm = document.getElementById('passwordConfirm').value

            if(password !== passwordConfirm){
                setErrors('Passwords must match')
                setTimeout(() => {
                    setLoading(false)
                },750)
                return
            }

            var res = await resetPassword(tokens, password)
            if(res.status === '400'){
                setErrors(res.message)
                setLoading(false)
                return
            }

            if(errors) setErrors(false)
            setResetComplete(true)
            setLoading(false)
        } catch(e){
            if(e.errorCode){
                setErrors(errorHandler(e.message))
            }
            setLoading(false)
        }
    }

    const tokens = getConfirmationId()

    if(!tokens.token || !tokens.tokenId) return <div>This link is not valid</div>

    return (
        <div style={{width:'100%',height:'100vh',backgroundColor:"#564256"}}>
            <div style={{paddingTop:'10vh'}}>
                <section className="mr-auto ml-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pb-4 bg-light" style={{boxShadow:'0px 5px 20px rga(0,0,0,0.1)',height:'auto',borderRadius:10}}>
                    <div className="text-center pt-4">
                        <span style={{fontSize:'1.50rem'}}><BookOpen cllasName="mr-2"/> Nans Cookbook</span>  
                    </div>

                    {resetComplete ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <div className="text-center mr-auto ml-auto">
                                    <span>Your password has been reset.  You may now login.</span>
                                </div>
                                {errors && <p>{errors}</p>}
                                <div class="form-group mt-4">
                                    <Link to="/">Home</Link>
                                </div>
                            </form>
                        </div>
                    : ''}

                    {!resetComplete ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-2">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                resetMyPassword()
                            }}>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Password</label>
                                    <input required type="password" class="form-control" id="password" aria-describedby="passwordHelp"></input>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Retype Password</label>
                                    <input required type="password" class="form-control" id="passwordConfirm" aria-describedby="passwordHelp"></input>
                                </div>

                                {!loading ? 
                                    <button type="submit" class="btn btn-outline-add col-12 mt-4">Reset Password</button>
                                :
                                    <button type="submit" class="btn btn-outline-add col-12 mt-4">
                                        <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </button>
                                }

                                {errors && <div className="mt-2 text-center">
                                    <span style={{color:"red"}} className="mt-2">{errors}</span>
                                </div> }

                                <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                    <span className="mr-auto ml-auto col-12 col-xl-6">
                                        <Link to="/" className="text-info">Home</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    : ''}
                </section>
            </div>
        </div>
    )
}