import React, {useState} from 'react'
import {Stitch, UserPasswordCredential,UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk'
import {Link} from 'react-router-dom'
import errorHandler from '../../lib/ErrorHandler'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'
import {BookOpen,W} from 'react-feather'

export default function LoginPage(props){
    const [errors, setErrors] = useState(false)
    const [confirmationRequired, setConfirmationRequired] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    const [resendConfirmationLoading, setResendConfirmationLoading] = useState(false)
    const {authCheck} = props.globalContext

    const userLogin = async () => {
        var client = Stitch.getAppClient(process.env.REACT_APP_STITCH_ID)
        setLoginLoading(true)
        if(errors) setErrors(false)
        try {
            await client.auth.loginWithCredential(new UserPasswordCredential(document.getElementById('email').value, document.getElementById('password').value))
            setTimeout(() => {
                setLoginLoading(false)
                authCheck()
            }, 1000)
        } catch (e){
            setTimeout(() => {
                if(e.message === 'confirmation required') setConfirmationRequired(true)
                setErrors(errorHandler(e.message))
                setLoginLoading(false)
            }, 1000)
        }
    }

    const sendConfirmationEmail = async () => {
        try {
            setResendConfirmationLoading(true)

            let res = await fetch(`https://stitch.mongodb.com/api/client/v2.0/app/${process.env.REACT_APP_STITCH_ID}/auth/providers/local-userpass/confirm/call`, {
                method: "POST",
                body: JSON.stringify({email: document.getElementById('email').value})
            })

            if(res.status === 404){
                let result = await res.json()
                setErrors(errorHandler(result.error))
                setResendConfirmationLoading(false)
            } else {
                setConfirmationRequired(false)
                toast('Confirmation Email Sent!')
                setResendConfirmationLoading(false)
                setErrors(false)
            }
        } catch(e){
            setErrors(errorHandler(e.errorMessage))
            setResendConfirmationLoading(false)
        }
    }

    return (
        <div style={{minHeight:'100vh',maxHeight:'100%',backgroundColor:"#564256"}}>
            <div style={{paddingTop:'10vh'}}>
                <section className="mr-auto ml-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pb-4 bg-light" style={{boxShadow:'0px 5px 20px rga(0,0,0,0.1)',height:'auto',borderRadius:10}}>
                    <div className="text-center pt-4">
                        <span style={{fontSize:'1.50rem'}}><BookOpen cllasName="mr-2"/> Nans Cookbook</span>  
                    </div>
                    <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            userLogin()
                        }}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input required type="email" className="form-control" id="email" aria-describedby="emailHelp"></input>
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input required type="password" className="form-control" id="password"></input>
                            </div>
                            <div>
                                {!loginLoading ? 
                                    <button type="submit" className="btn btn-outline-add col-12 mt-4">Submit</button>
                                :
                                    <button type="button" className="btn btn-primary col-12 mt-4">
                                        <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </button>
                                }
                            </div>
                            
                            {errors ? 
                                <div className="mt-2">
                                    <span style={{color:"red"}} className="mt-2">{errors}</span>
                                </div> 
                            : ''}
                            <div class="form-group mt-4">
                                {confirmationRequired ? 
                                    <div>
                                        {!resendConfirmationLoading ? 
                                            <button onClick={() => sendConfirmationEmail()} type="button" className="btn btn-outline-secondary col-12">Resend Confirmation Email</button>
                                        :
                                            <button type="button" className="btn btn-outline-secondary col-12">
                                                <Spinner animation="border" role="status" size="sm">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </button>
                                        }
                                    </div>
                                : ''}
                                <div className="d-flex flex-wrap text-center mb-2">
                                    <span className="mr-auto ml-auto col-12 col-xl-6 mt-2">
                                        <Link to="/create-account" className="text-info">Create Account</Link>
                                    </span>
                                    <span className="ml-auto mr-auto col-12 col-xl-6 mt-2">
                                        <Link to="/password-reset" className="text-info">Reset my password</Link>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}