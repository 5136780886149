import React, {useState, useMemo, useCallback} from 'react';
import GlobalStateContext from './GlobalStateContext'
//import { listRecipes, createRecipe } from '../../lib/api'
import {listRecipes, createRecipe, checkAuthStatus, logout, getUserData, removeFavorite, addFavorite } from '../../lib/stitch'
import {toast} from 'react-toastify'

const GlobalStateProvider = props => {
    const [showRecipeModal, setShowRecipeModal] = useState(false)
    const [recipes, setRecipes] = useState(false)
    const [searchTerm, setSearchTerm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(null)
    const [userData, setUserData] = useState(false)

    const getRecipesList = async () => {
        const prom = await listRecipes()
        setRecipes(prom)
    }

    const likeDislikeRecipe = async (recipeId, favorite) => {
        try { //should we add or remove the recipe
            if(favorite) {
                var prom = await removeFavorite(userData.id, recipeId)
                setUserData(await getUserData())
                getRecipesList()
                toast('Updated!')
            } else {
                const prom = await addFavorite(userData.id, recipeId)
                setUserData(await getUserData())
                getRecipesList()
                toast('Recipe Loved!')
            }
        } catch(e) {
            
        }
    }

    const submitRecipe = (values) => {
        setLoading(true)
        const prom = ''

        setTimeout(async () => {
            await createRecipe(values, userData.customData.families[0])
            setLoading(false)
            setShowRecipeModal(false)
            toast('Recipe Added!')
            getRecipesList()
        }, 250)
        return prom
    }
    
    const authCheck = async () => {
        var prom = await checkAuthStatus()
        setIsLoggedIn(prom)
        if(prom) setUserData(await getUserData())
    }

    const logoutUser = async () => {
        await logout()
        setTimeout(() => {
            setIsLoggedIn(null)
            window.location.replace(process.env.REACT_APP_BASE_APP_URL)
        }, 1000)
    }

    const applySearchFilter = useCallback(() => {
        if(!recipes) return false
        if(recipes && !searchTerm) return recipes
        if(recipes && searchTerm) return recipes.filter(a => a.recipeName.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1 || a.recipeDescription.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1)
    },[recipes, searchTerm])

    const filteredRecipes = useMemo(() => applySearchFilter(), [applySearchFilter])

    return (
        <GlobalStateContext.Provider // this is where we expose state values and functions to the rest of the application
            value={{
                state: { // for organization reasons I like keep all state values inside a state object
                    showRecipeModal,
                    recipes,
                    searchTerm,
                    loading,
                    isLoggedIn,
                    userData
                },
                setShowRecipeModal: (bool) => setShowRecipeModal(bool),
                setRecipes: (arr) => setRecipes(arr),
                getRecipesList: () => getRecipesList(),
                setSearchTerm: (str) => setSearchTerm(str),
                setLoading: (bool) => setLoading(bool),
                submitRecipe: (obj) => submitRecipe(obj),
                setIsLoggedIn: (bool) => setIsLoggedIn(bool),
                authCheck: () => authCheck(),
                logoutUser: () => logoutUser(),
                likeDislikeRecipe: (id, favorite) => likeDislikeRecipe(id, favorite),
                filteredRecipes
            }}
        >
            {props.children}
        </GlobalStateContext.Provider>
    )
}

export default GlobalStateProvider;