import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Stitch,UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk'
import errorHandler from '../../lib/ErrorHandler'
import {Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {createUserRecord} from '../../lib/stitch'
import {BookOpen} from 'react-feather'

export default function CreateAccount(){
    const [errors, setErrors] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(false)

    const addUser = async () => {
        try {
            setLoading(true)
            var client = Stitch.getAppClient(process.env.REACT_APP_STITCH_ID)
            var emailPasswordClient = client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)

            var email = document.getElementById('email').value
            var passwordOne = document.getElementById('password').value
            var passwordTwo = document.getElementById('passwordMatch').value
            var firstName = document.getElementById('firstName').value
            var lastName = document.getElementById('lastName').value

            if(passwordOne !== passwordTwo) {
                setErrors('Passwords must match')
                setLoading(false)
                return
            }

            if(errors) setErrors(false)
            var prom = await emailPasswordClient.registerWithEmail(email, passwordOne)
            
            if(prom && prom.errorCode) {
                setErrors(errorHandler(prom.message))
                setLoading(false)
                return
            }

            //Insert user information into user table
            prom = await createUserRecord(firstName, lastName, email)

            setCompleted(true)
            if(errors) setErrors(false)
            setLoading(false)
            toast("Account Created!")
        } catch(e){
            setErrors(errorHandler(e.message))
            setLoading(false)
        }
    }

    return (
        <div style={{width:'100%',minHeight:'100vh',maxHeight:'100%',backgroundColor:"#564256",paddingTop:'2rem',paddingBottom:'2rem'}}>
            <div>
                <section className="mr-auto ml-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pt-4 pb-4 bg-light" style={{boxShadow:'0px 5px 20px rga(0,0,0,0.1)',height:'100%',borderRadius:10}}>
                    <div className="text-center pt-4">
                        <span style={{fontSize:'1.50rem'}}><BookOpen/> Nans Cookbook</span>  
                    </div>
                    {completed ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <div className="text-center">
                                    <span>Success! Before logging in, you must confirm your account.  You will recieve an email to do this.</span>
                                </div>
                                {errors && <p>{errors}</p>}
                                <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                    <span className="mr-auto ml-auto col-12 col-xl-6">
                                        <Link to="/">Home</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    : ''}
                    
                    
                    {!completed ? 
                        <React.Fragment>
                            <div className="text-center mt-4">
                                <span>Let's create your account</span>
                            </div>
                            <div className="col-10 mr-auto ml-auto pt-3 mt-3">
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    addUser()
                                }}>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">First Name</label>
                                        <input required type="text" class="form-control form-control-sm" id="firstName" aria-describedby="emailHelp"></input>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Last Name</label>
                                        <input required type="text" class="form-control form-control-sm" id="lastName" aria-describedby="emailHelp"></input>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email address</label>
                                        <input required type="email" class="form-control form-control-sm" id="email" aria-describedby="emailHelp"></input>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Password</label>
                                        <input required type="password" class="form-control form-control-sm" id="password"></input>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Confirm Password</label>
                                        <input required type="password" class="form-control form-control-sm" id="passwordMatch"></input>
                                    </div>
                                    {!loading ? 
                                        <button type="submit" className="btn btn-outline-add col-12 mt-4">Sign Up</button>
                                    : 
                                        <button type="submit" className="btn btn-outline-add col-12 mt-4">
                                            <Spinner animation="border" role="status" size="sm">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </button>
                                    }
                                    
                                    {errors ? <div className="mt-2 text-center">
                                        <span style={{color:"red"}} className="mt-2">{errors}</span>
                                    </div>  : ''}

                                    <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                        <span className="mr-auto ml-auto col-12 col-xl-6">
                                            <Link to="/login" className="text-info">Login</Link>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </React.Fragment>
                    :   ''}
                </section>
            </div>
        </div>
    )
}