import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'

export default function ImageModal(props){
    const {showImageModal, setShowImageModal, image} = props
    const handleClose = () => setShowImageModal(false)

    return (
        <Modal show={showImageModal} onHide={handleClose} size="lg" className="col-12">
            <Modal.Header closeButton style={{backgroundColor:'#564256',color:'white'}}></Modal.Header>
            <Modal.Body>
                <img
                    style={{height:'100%',width:'100%'}}
                    src={image}
                    alt={image.name}
                ></img>
            </Modal.Body>
        </Modal>
    )
}