import React, {useState} from 'react'
import {Navbar,Nav} from 'react-bootstrap'
import {PlusCircle} from 'react-feather'

export default function MenuBar(props){
    const {setShowRecipeModal} = props.globalContext

    return (
        <Navbar fixed="bottom" variant="dark" className="bg-menu" expand="lg">
            <Nav className="ml-auto mr-auto">
                {window.innerWidth < 1000 ?
                    <Nav.Link onClick={() => setShowRecipeModal(true)} className="text-white mt-1">
                        <PlusCircle /> Add Recipe
                    </Nav.Link>
                : ''}
            </Nav>
        </Navbar>
    )
}