import React from 'react'
import {useFieldArray} from 'react-hook-form'
import {Form,Col} from 'react-bootstrap'
import {errorExists} from '../../lib/fieldHelpers'
import {Trash2} from 'react-feather'

export default function Directions(props){
    const { control, register, errors } = props
    const { fields, append, remove } = useFieldArray({control, name: 'directions'})
  
    return (
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label className='lead border-bottom w-100'>Directions</Form.Label>
        <Form.Row>
          <Col xs="2" xl='1'>
            <Form.Label>Step</Form.Label>
          </Col>
          <Col>
            <Form.Label>Description</Form.Label>
          </Col>
        </Form.Row>
        {fields.map((item, index) => {
          return (
            <Form.Row key={item.id} className="mt-1">
              <Col xs='2' xl='1'>
                <Form.Control
                  className={errorExists(errors, 'directions', index, 'step') ? 'is-invalid' : ''}
                  name={`directions[${index}].step`}
                  ref={register()}
                  size="sm"
                  value={index+1}
                  readonly={true}
                  disabled={true}
                />
              </Col>
              <Col>
                <Form.Control
                  className={errorExists(errors, 'directions', index, 'direction') ? 'is-invalid' : ''}
                  size="sm"
                  name={`directions[${index}].direction`}
                  ref={register({required:true})}
                />
              </Col>
              <Trash2 style={{cursor:"pointer"}} className="mt-0" onClick={() => remove(index)}>Delete</Trash2>
            </Form.Row>
          );
        })}
        <button type="button" className="mt-2 col-12 btn btn-outline-add btn-sm" size="sm" onClick={() => append({step: '', direction: ''})}>Add</button>
      </Form.Group>
    )
  }