import React, {useEffect} from 'react'
import Loading from './Loading'
import {Form,InputGroup,Button,FormControl} from 'react-bootstrap'
import {X} from 'react-feather'
//import sharp from 'sharp'

export default function Home(props){
    const { recipes } = props.globalContext.state
    const { getRecipesList, setSearchTerm, filteredRecipes } = props.globalContext

    useEffect(() => {
        if(!recipes) getRecipesList()
    }, [recipes])

    const setStyles = () => {
        if(window.innerWidth < 700) return {minWidth:'95%',maxWidth:'95%'}
        return {minWidth:'48%',maxWidth:'48%'}
    }

    return (
        <div style={{minHeight:'85vh',height:'90%',maxWidth:1300,marginLeft:'20%',marginRight:'20%',marginTop:55,marginBottom:'5rem',overflow:'scroll'}} className="mr-auto ml-auto">
            {!recipes && <Loading/>}
            {recipes ? 
                <React.Fragment>
                    <div className="d-flex flex-nowrap justify-context-center mr-auto ml-auto" style={{paddingTop:'1.5rem',paddingTop:'1.5rem',borderBottom:'2px solid #564256',width:'95%'}}>
                        <Form onSubmit={(e) => e.preventDefault()} inline className="mr-auto ml-auto text-center" style={{width:'100%'}}>
                            <InputGroup className="mb-3" style={{width:'100%'}}>
                                <FormControl
                                    type='search'
                                    placeholder="Find your next recipe"
                                    aria-label="Find your next recipe"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </InputGroup>
                        </Form>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center" style={{paddingTop:'1.5rem',maxWidth:1500}}>
                        {filteredRecipes.length > 0 && filteredRecipes.map(recipe => {
                            return (
                                <div key={recipe._id.toString()} onClick={() => {
                                    props.history.push(`/recipes/${recipe._id}`)
                                    window.scrollTo(0,0)
                                }} className="card mx-1 my-1" style={setStyles()}>
                                    {/* <img src="..." class="card-img-top" alt="..."></img> */}
                                    <div className="card-body">
                                        <h5 className="card-title lead"><b>{recipe.recipeName}</b></h5>
                                        <p className="card-text lead" style={{fontSize:'.9rem'}}>{recipe.recipeDescription}</p>
                                    </div>
                                </div>
                            )
                        })}

                        {filteredRecipes.length === 0 ? 
                            <span className="col-12 mr-auto ml-auto text-center lead">No recipes found!</span>
                        : ''}
                    </div>
                </React.Fragment>
            : ''}
            
    </div>
    )
}