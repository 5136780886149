import React, {useEffect} from 'react';
import LoginPage from './components/User Life Cycle/LoginPage'
import GlobalStateContext from './contexts/global/GlobalStateContext'
import {Switch,BrowserRouter,Route} from 'react-router-dom'
import './App.css';
import Home from './components/Home'
import MenuBar from './components/MenuBar'
import Footer from './components/Footer'
import RecipeDetails from './components/Recipe/RecipeDetails'
import AddRecipeModal from './components/Recipe/AddRecipeModal'
import CreateAccount from './components/User Life Cycle/CreateAccount'
import EmailConfirmation from './components/User Life Cycle/EmailConfirmation'
import PasswordReset from './components/User Life Cycle/PasswordReset'
import ResetMyPassword from './components/User Life Cycle/ResetMyPassword'
import Favorites from './components/Recipe/Favorites'
import FamilyCookbook from './components/Recipe/FamilyCookbook'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Main(props){
  const {isLoggedIn} = props.globalContext.state
  const {authCheck} = props.globalContext

  useEffect(() => {
    if(isLoggedIn === null) authCheck()
  })

  if(isLoggedIn === null) return <div>Loading...</div>

  if(isLoggedIn === false){
    return (
      <div style={{minHeight:'100vh',maxHeight:'100%'}}>
        <GlobalStateContext.Consumer>
          {globalContext => (
            <BrowserRouter>
              <ToastContainer/>
              <Switch>
                <Route exact path="/create-account" render={(props) => <CreateAccount {...props}/>}/>
                <Route path="/email-confirmation" render={(props) => <EmailConfirmation {...props}/>}/>
                <Route path="/password-reset" render={(props) => <PasswordReset {...props}/>}/>
                <Route path="/reset-my-password" render={(props) => <ResetMyPassword {...props}/>}/>
                <Route exact render={(props) => <LoginPage globalContext={globalContext} {...props}/>}/>
              </Switch>
            </BrowserRouter>
          )}
        </GlobalStateContext.Consumer>
      </div>
    )
  }
  
  return (
    <div style={{minHeight:'100vh',height:'100%',overflow:'hidden'}} className="bg-light">
      <GlobalStateContext.Consumer>
        {globalContext => (
          <BrowserRouter>
            <ToastContainer
              newestOnTop
              
            />
            <MenuBar globalContext={globalContext}/>
            <Footer globalContext={globalContext}/>
            <AddRecipeModal globalContext={globalContext}/>
            <Switch>
              <Route path="/favorites" render={(props) => <Favorites globalContext={globalContext} {...props}/>}/>
              {/* <Route path="/family-cookbook" render={(props) => <FamilyCookbook globalContext={globalContext} {...props}/>}/> */}
              <Route path="/recipes/:id" render={(props) => <RecipeDetails globalContext={globalContext} {...props}/>}/>
              <Route path="*" render={(props) => <Home globalContext={globalContext} {...props}/>}/>
            </Switch>
          </BrowserRouter>
        )}
      </GlobalStateContext.Consumer>
    </div>
    
  )
}

export default function App(){
  return (
    <GlobalStateContext.Consumer>
      {globalContext => (
        <Main globalContext={globalContext}/>
      )}
    </GlobalStateContext.Consumer>
  )
}