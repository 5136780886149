import {Stitch,RemoteMongoClient,BSON,FunctionCredential,UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk'
import errorHandler from './ErrorHandler'

const getClient = async () => {
    var client = ''
    try {
      client = Stitch.defaultAppClient
    } catch(e) {
      client = await Stitch.initializeDefaultAppClient(process.env.REACT_APP_STITCH_ID)
    }
    return client
}

//AUTHENTICATION LIFECYCLE OPERATIONS
export const checkAuthStatus = async () => {
    var client = await getClient()
    return client.auth.isLoggedIn
}

export const logout = async () => {
    var client = await getClient()
    await client.auth.logout()
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.all_auth_infos`)
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.auth_info`)
    return
}

export const getUserData = async () => {
  var client = await getClient()
  await client.auth.refreshCustomData()
  return client.auth.currentUser
}

export const initiatePasswordReset = async (email) => {
  try {
    var client = await getClient()
    var emailPasswordClient = client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
    await emailPasswordClient.callResetPasswordFunction(email.toLowerCase(), new BSON.ObjectId().toString())
    return {status: "200", message: 'Success'}
  } catch(e) {
    return {status: "400", message: errorHandler(e.message)}
  }
}

export const resetPassword = async (tokens, password) => {
  try{
    var client = await getClient()
    var emailPasswordClient = client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
    await emailPasswordClient.resetPassword(tokens.token, tokens.tokenId, password)
    return {status: '200', message: 'Success'}
  } catch(e) {
    return {"status": "400", message: errorHandler(e.message)}
  }
}

export const addRemoveFavorite = async (recipeId, favorite) => {
  var client = await getClient()
  //var update = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('user_data').updateOne({_id: new BSON.ObjectId(recipeId)}, {$unset: {""}})
}

export const removeFavorite = async (userId, recipeId) => {
  try {
    var client = await getClient()
    var mongodb = client.getServiceClient(RemoteMongoClient.factory, process.env.REACT_APP_DEFAULT_APP)

    var update = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('user_data').updateOne({user_id: userId}, {$pull: {favorites: recipeId}})
  } catch(e) {
  }
}

export const addFavorite = async (userId, recipeId) => {
  try {
    var client = await getClient()
    var mongodb = client.getServiceClient(RemoteMongoClient.factory, process.env.REACT_APP_DEFAULT_APP)

    var update = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('user_data').updateOne({user_id: userId}, {$push: {favorites: recipeId}})
  } catch(e) {
    
  }
}

export const listRecipes = async () => {
    var client = ''
    try {
      client = Stitch.defaultAppClient
    } catch(e) {
      client = await Stitch.initializeDefaultAppClient(process.env.REACT_APP_STITCH_ID)
    }
    
    var mongodb = client.getServiceClient(RemoteMongoClient.factory, process.env.REACT_APP_DEFAULT_APP)
    var recipes = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('recipes').aggregate([]).toArray()



    await create320Image()
    return recipes
}

export const createRecipe = async (recipe, familyId) => {
    var client = await getClient()

    var mongodb = client.getServiceClient(RemoteMongoClient.factory, process.env.REACT_APP_DEFAULT_APP)

    //Add keys
    recipe.created = new Date().toString()
    recipe.userOwner = client.auth.currentUser.id
    if(recipe.familyRecipe) recipe.familyid = familyId

    //Remove keys
    let files = [...recipe.files]
    delete recipe.files

    //Store each of our files in AWS S3
    for(var i = 0; i<= files.length-1;i++){
      var fileType = files[i].file[0].source.split('/')[1].split(';')[0]
      files[i].file[0].fileName = `${new Buffer(`${files[i].file[0].path}-${new Date().getTime()}`).toString('base64')}.${fileType}`
      files[i].file[0].fileType = fileType
      await client.callFunction('putImage', [files[i].file[0]])
      delete files[i].file[0].source
      delete files[i].file[0].buffer
      delete files[i].file[0].preview
      delete files[i].file[0].size
    }

    //add the new files object to the recipe
    recipe.files = files

    var recipes = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('recipes').insertOne(recipe)

    return true
}

export const loadRecipeImages = async (recipe_id) => { //For each file, load a smaller version of it
  var client = await getClient()
  var mongodb = client.getServiceClient(RemoteMongoClient.factory, process.env.REACT_APP_DEFAULT_APP)
  
  try {
    //load our file id's
    var files = await mongodb.db(process.env.REACT_APP_DEFAULT_DB).collection('recipes').aggregate([{$match: {_id: new BSON.ObjectId(recipe_id)}}, {$project: {files: 1}}]).toArray()
    files = files[0].files
    var images = []
    var width = setWidth()
    var height = setHeight()
    
    for(var i = 0;i<=files.length-1; i++){
      var image = await create320Image(files[i].file[0].fileName, width, height)
      images.push(image)
    }
  
    return images
  } catch(e) {
    
  }
}

export const setWidth = () => {
  if(window.innerWidth <=600) return 60
  if(window.innerWidth <=1000) return 300
  if(window.innerWidth <=1500) return 300
}

export const setHeight = () => {
  if(window.innerWidth <=600) return 60
  if(window.innerWidth <=1000) return 200
  if(window.innerWidth <=1500) return 250
}

export const create320Image = async (name, width, height) => {
  if(!name) return
  const imageRequest = JSON.stringify({
      bucket: 'nanscookbook-recipes',
      key: name,
      edits: {
        resize: {width: width || 320, height: height || 240},
        rotate: null
      }
  });
  const url = `${process.env.REACT_APP_IMAGE_HANDLER}/${btoa(imageRequest)}`

  var prom = await fetch(url)
  var res = await prom.blob()
  var blob = await URL.createObjectURL(res)
  return {name: name, blob: blob}
}

export const getFullImage = async (name) => {
  if(!name) return
  const imageRequest = JSON.stringify({
      bucket: 'nanscookbook-recipes',
      key: name,
      edits: {
        rotate: null
      }
  });
  const url = `${process.env.REACT_APP_IMAGE_HANDLER}/${btoa(imageRequest)}`

  var prom = await fetch(url)
  var res = await prom.blob()
  var blog = await URL.createObjectURL(res)
  return blog
}

export const createUserRecord = async (firstName, lastName, email) => {
  var client = await getClient()

  try {
    await client.auth.loginWithCredential(new FunctionCredential({}))
    var prom = await client.callFunction('createAccount', [firstName, lastName, email])
    await client.auth.logout()
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.all_auth_infos`)
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.auth_info`)

    return prom
  } catch (e) {
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.all_auth_infos`)
    localStorage.removeItem(`__stitch.client.${process.env.REACT_APP_STITCH_ID}.auth_info`)
    return e
  }
}