import React, {useEffect, useState} from 'react'
import {Row,Col,Spinner} from 'react-bootstrap'
import ImageDropZone from './ImageDropZone'
import {useForm} from 'react-hook-form'
import Loading from '../Loading'
import {loadRecipeImages} from '../../lib/stitch'
import {ArrowLeftCircle,Heart} from 'react-feather'
import ImageModal from './ImageModal'

export default function RecipeDetails(props){
    const { recipes, userData } = props.globalContext.state
    const { getRecipesList, likeDislikeRecipe } = props.globalContext
    const [recipeImages, setRecipeImages] = useState(null)
    const [recipeDetails, setRecipeDetails] = useState(null)
    const [showImageModal, setShowImageModal] = useState(false)
    const [image, setImage] = useState(false)

    useEffect(() => {
        if(!recipes) getRecipesList()
        if((props.match.params.id || props.favorites) && recipes && userData && !recipeDetails) getRecipeDetails()
        if(recipes && recipeDetails && !props.favorites && recipeImages === null) getRecipeImages()
    })

    const getRecipeDetails = () => {
        var details = recipes.find(a => a._id.toString() === props.match.params.id)
        if(!userData.customData || !userData.customData.favorites) details.favorite = false //this protects against where a user account may be corrupted
        else details.favorite = userData.customData.favorites.includes(details._id.toString())
        setRecipeDetails(details)
    }

    const getRecipeImages = async () => {
        var images = await loadRecipeImages(recipeDetails._id.toString())
        setRecipeImages(images)
    }

    const {control, register, handleSubmit,watch,errors, setValue} = useForm({
        defaultValues: {
          ingredients: [{name: '', quantity: '', unit: ''}],
          directions: [{step: '1', direction: ''}],
          files: recipeDetails ? recipeDetails.files : []
        }
    })

    if(!recipes || recipeDetails === null) return <Loading/>
    if(recipeDetails === false) {
        return (
            <main className="bg-light col-12" style={{minHeight:'91vh',marginTop:60}}>
                <div className="mr-auto ml-auto col-12 col-xs-12 col-md-8 col-lg-8 col-xl-6 text-center">
                    No recipe found!
                </div>
            </main>
        )
    }

    return (
        <main className="bg-light col-12" style={{minHeight:'85vh',marginTop:55,marginBottom:50}}>
            <div className="mr-auto ml-auto col-12 col-xs-12 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                <div className="d-flex flex-wrap pt-4 mr-auto ml-auto pb-2 border-bottom">
                    <span className="mr-auto lead mt-2" style={{maxWidth:1000,fontSize:'1.5rem',fontWeight:400}}>
                        <ArrowLeftCircle className="mr-4" onClick={() => {
                            props.history.goBack()
                            window.scrollTo(0,0)
                            }} style={{cursor:"pointer"}}/> 
                        <span className="mr-2">{recipeDetails.recipeName}</span>
                        <Heart onClick={async () => {
                            await likeDislikeRecipe(recipeDetails._id.toString(), recipeDetails.favorite)
                            getRecipeDetails()
                        }} style={recipeDetails.favorite ? {cursor:"pointer",color:'red',fill:'red'} : {cursor:"pointer"}} className="mr-4"/>
                    </span>

                    <span className='align-text-bottom pt-4' style={{maxWidth:1000,fontSize:'1rem'}}> Author: <b style={{fontWeight:600}}>{recipeDetails.recipeAuthor}</b></span>
                </div>
                <div className="d-flex pt-4 mr-auto ml-auto pb-4 border-bottom">
                    <span className="lead" style={{fontSize:'1rem'}}>
                        {recipeDetails.recipeDescription}
                    </span>
                </div>
                <div className="mr-auto ml-auto pb-2 mt-2">
                    {recipeImages && recipeImages.length > 0 ? 
                        <ImageDropZone disabled control={control} register={register} handleSubmit={handleSubmit} watch={watch} errors={errors} setValue={setValue} files={recipeImages} setImage={setImage} setShowImageModal={setShowImageModal}/>
                    : 
                        <div className="text-center mr-auto ml-auto mt-4">
                            <Spinner className="mr-auto ml-auto" animation="border" role="status">
                                <span className="sr-only mr-auto ml-auto">Loading...</span>
                            </Spinner>
                        </div>
                    }
                </div>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-xl-6 col-md-6">
                        <div className="d-flex pt-4 mr-auto ml-auto">
                            <span style={{fontFamily:'sans-serif',fontWeight:400,fontSize:'1.5rem'}}>
                                Ingredients
                            </span>
                        </div>
                        <div className="mr-auto ml-auto pb-2">
                            {recipeDetails.ingredients.map(ingredient => {
                                return (
                                    <Row className="mt-4 mb-4">
                                        <Col>
                                            <span>{ingredient.quantity} {ingredient.unit.toUpperCase() === 'EA' ? "" : ingredient.unit} {ingredient.name}</span>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 col-md-12 col-lg-6">
                        <div className="d-flex pt-4 mr-auto ml-auto">
                            <span style={{fontFamily:'sans-serif',fontWeight:400,fontSize:'1.5rem'}}>
                                Directions
                            </span>
                        </div>
                        <div className="mr-auto ml-auto pb-2">
                            {recipeDetails.directions.map(ingredient => {
                                return (
                                    <Row className="mt-4 mb-4">
                                        <Col xl={12}>
                                            <span>{ingredient.step}) </span>
                                            <span className="ml-4">{ingredient.direction}</span>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ImageModal showImageModal={showImageModal} setShowImageModal={setShowImageModal} image={image}/>
        </main>
    )
}