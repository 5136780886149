import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import errorHandler from '../../lib/ErrorHandler'
import {Spinner} from 'react-bootstrap'
import {BookOpen} from 'react-feather'
import {initiatePasswordReset} from '../../lib/stitch'

export default function PasswordReact(props){
    const [errors, setErrors] = useState(false)
    const [resetComplete, setResetComplete] = useState(false)
    const [loading, setLoading] = useState(false)

    const passwordReset = async () => {
        setLoading(true)
        try{
            var res = await initiatePasswordReset(document.getElementById('email').value)

            if(res.status === '400'){
                setTimeout(() => {
                    setErrors(res.message)
                    setLoading(false)
                    return
                },750)
            } else {
                if(errors) setErrors(false)

                setTimeout(() => {
                    setResetComplete(true)
                    setLoading(false)
                }, 1000)
            }
        } catch(e){
            setTimeout(() => {
                setErrors(errorHandler(e.message))
                setLoading(false)
            }, 1000)
        }
    }

    return (
        <div style={{width:'100%',height:'100vh',backgroundColor:"#564256"}}>
            <div style={{paddingTop:'10vh'}}>
                <section className="mr-auto ml-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pb-4 bg-light" style={{boxShadow:'0px 5px 20px rga(0,0,0,0.1)',height:'auto',borderRadius:10}}>
                    <div className="text-center pt-4">
                        <span style={{fontSize:'1.50rem'}}><BookOpen cllasName="mr-2"/> Nans Cookbook</span>  
                    </div>
                    {resetComplete ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                passwordReset()
                            }}>
                                <div className="text-center mr-auto ml-auto">
                                    <span>Your password reset request has been recieved.  You will receive an email with instructions.</span>
                                </div>
                                {errors && <p>{errors}</p>}
                                <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                    <span className="mr-auto ml-auto col-12 col-xl-6">
                                        <Link to="/" className="btn btn-outline-add col-12 mt-4">Home</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    : ''}

                    {!resetComplete ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                passwordReset()
                            }}>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email address</label>
                                    <input required type="email" class="form-control" id="email" aria-describedby="emailHelp"></input>
                                </div>
                                {!loading ? 
                                    <button type="submit" class="btn btn-outline-add col-12 mt-4">Submit</button>
                                :
                                    <button type="submit" class="btn btn-outline-add col-12 mt-4">
                                        <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </button>
                                }

                                {errors && <div className="mt-2 text-center">
                                    <span style={{color:"red"}} className="mt-2">{errors}</span>
                                </div> }
                                <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                    <span className="mr-auto ml-auto col-12 col-xl-6">
                                        <Link to="/login" className="text-info">Login</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    : ''}
                </section>
            </div>
        </div>
    )
}