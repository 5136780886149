import React, {useState} from 'react'
import {LogOut} from 'react-feather'
import {Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {BookOpen,Heart,PlusCircle} from 'react-feather'
import {Navbar,Nav} from 'react-bootstrap'

export default function MenuBar(props){
    const {logoutUser, setShowRecipeModal} = props.globalContext
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(false)

    return (
        <Navbar fixed="top" variant="dark" className="bg-menu" expand="lg" expanded={expanded}>
            <Navbar.Brand>
                <Link to="/" style={{fontSize: window.innerWidth > 380 ? '1.5rem' : '1rem',color:'white'}} href="/">
                    <BookOpen className="mr-2 text-white"></BookOpen> <span className="text-white">Nan's Cookbook</span>
                </Link>
            </Navbar.Brand>
            
            <Navbar.Toggle onClick={() => {
                setExpanded(expanded ? false : 'expanded')
            }} aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" className="w-100">
                <Nav className="ml-auto">
                    <Nav.Link className="text-white mt-1">
                        <Link className="text-white mt-1" onClick={() => setExpanded(false)} to="/favorites"><Heart style={{fill:'red',color:'red'}}/> Favorites</Link>
                    </Nav.Link>
                    <Nav.Link onClick={() => {
                        setShowRecipeModal(true)
                        setExpanded(false)
                    }} className="text-white mt-1">
                        <PlusCircle /> Add Recipe
                    </Nav.Link>

                    <Nav.Link className="text-white mt-1 mr-auto ml-0">
                        {!loading ?
                            <span onClick={() => {
                                setLoading(true)
                                logoutUser()
                            }} style={{cursor:'pointer'}}><LogOut className="text-white"/> Sign Out</span>
                        : 
                            <Spinner size="sm" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}