import React, {useState,useEffect} from 'react'
import Loading from '../Loading'

export default function Favorites(props){
    const {getRecipesList, getUserData} = props.globalContext
    const {recipes, userData} = props.globalContext.state
    const [favoriteRecipes, setFavoriteRecipes] = useState(null)
    
    const getFavoriteRecipes = () => {
        var favoritesList = recipes.filter(a => userData.customData.favorites.includes(a._id.toString()))
        setFavoriteRecipes(favoritesList)
        return
    }

    useEffect(() => {
        if(!recipes) getRecipesList()
        if(recipes && userData && favoriteRecipes === null) getFavoriteRecipes()
    })

    const setStyles = () => {
        if(window.innerWidth < 1200) return {minWidth:'95%',maxWidth:'95%'}
        return {minWidth:'48%',maxWidth:'48%'}
    }

    if(favoriteRecipes === null) return <Loading/>
    if(favoriteRecipes === false) return <div>error</div> 

    return (
        <main className="bg-light col-12" style={{minHeight:'91vh',marginTop:55}}>
            <div className="mr-auto ml-auto col-12 col-xs-12 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                <div className="d-flex flex-wrap pt-4 mr-auto ml-auto pb-2 border-bottom">
                    <span className="mr-auto lead mt-2" style={{maxWidth:1000,fontSize:'1.5rem',fontWeight:400}}>
                        <span className="mr-2">Favorites</span>
                    </span>
                </div>
                <div className="d-flex flex-wrap justify-content-center" style={{paddingTop:'1.5rem',maxWidth:1500}}>
                    {favoriteRecipes.length > 0 && favoriteRecipes.map(recipe => {
                        return (
                            <div key={recipe._id.toString()} onClick={() => {
                                props.history.push(`/recipes/${recipe._id}`)
                                window.scrollTo(0,0)
                            }} className="card mx-1 my-1" style={setStyles()}>
                                {/* <img src="..." class="card-img-top" alt="..."></img> */}
                                <div className="card-body">
                                    <h5 className="card-title lead"><b>{recipe.recipeName}</b></h5>
                                    <p className="card-text lead" style={{fontSize:'.9rem'}}>{recipe.recipeDescription}</p>
                                </div>
                            </div>
                        )
                    })}

                    {favoriteRecipes && favoriteRecipes.length === 0 ?
                        <span className="col-12 mr-auto ml-auto text-center lead">You haven't selected any favorites!</span>
                    : ''}
                </div>
            </div>
        </main>
    )
}