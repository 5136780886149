import React, {useState,useEffect,useMemo} from 'react'
import {Link} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import {Stitch,UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk'
import queryString from 'query-string'
import {toast} from 'react-toastify'
import {BookOpen} from 'react-feather'
import ErrorHandler from '../../lib/ErrorHandler'

export default function EmailConfirmation(props){
    const [confirmationComplete, setConfirmationComplete] = useState(null)
    const [errors, setErrors] = useState(false)

    const getConfirmationId = () => {
        return queryString.parse(window.location.search)
    }

    const confirmUser = async () => {
        try {
            var client = Stitch.getAppClient(process.env.REACT_APP_STITCH_ID)
            var emailPasswordClient = client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
            await emailPasswordClient.confirmUser(tokens.token, tokens.tokenId)
            toast('Email has been confirmed! We are redirecting you to login...')

            setTimeout(() => {
                props.history.push('/')
            }, 1500)
            
        } catch(e){
            setErrors(e.message)
        }
    }

    const tokens = useMemo(() => getConfirmationId(), [])

    useEffect(() => {
        if(tokens) confirmUser()
    })

    return (
        <div style={{width:'100%',height:'100vh',backgroundColor:"#564256"}}>
            <div style={{paddingTop:'10vh'}}>
                <section className="mr-auto ml-auto col-12 col-sm-10 col-md-8 col-lg-6 col-xl-3 pb-4 bg-light" style={{boxShadow:'0px 5px 20px rga(0,0,0,0.1)',height:'auto',borderRadius:10}}>
                    <div className="text-center pt-4">
                        <span style={{fontSize:'1.50rem'}}><BookOpen cllasName="mr-2"/> Nans Cookbook</span>  
                    </div>
                    {confirmationComplete ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <div className="text-center mr-auto ml-auto">
                                    <span>Your account has been confirmed!.  You may now login.</span>
                                </div>
                                {errors && <p>{errors}</p>}
                                <div className="d-flex flex-wrap text-center mb-2 mt-4">
                                    <span className="mr-auto ml-auto col-12 col-xl-6">
                                        <Link to="/" className="btn btn-outline-add col-12 mt-4">Home</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    : ''}

                    {!confirmationComplete && !errors ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4 text-center">
                            <Spinner animation="border" role="status" size="lg">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    : ''}

                    {!confirmationComplete && errors ? 
                        <div className="col-10 mr-auto ml-auto pt-4 mt-4 text-center">
                            <p>{ErrorHandler(errors)}</p>
                            <Link to="/" className="text-info">Home</Link>
                        </div>
                    : ''}
                </section>
            </div>
        </div>
    )
}