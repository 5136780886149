import React from 'react'
import {useFieldArray} from 'react-hook-form'
import {Form,Col,Button} from 'react-bootstrap'
import {errorExists} from '../../lib/fieldHelpers'
import {Trash2} from 'react-feather'

export default function IngredientLines(props){
    const { control, register, errors } = props
    const { fields, append, remove} = useFieldArray({control, name: 'ingredients'})
  
    return (
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label className='lead border-bottom w-100'>Ingredients</Form.Label>
        <Form.Row>
          <Col>
            <Form.Label>Name</Form.Label>
          </Col>
          <Col xs='2' xl='1'>
            <Form.Label>Qty</Form.Label>
          </Col>
          <Col xs='2' xl='1'>
            <Form.Label>Unit</Form.Label>
          </Col>
        </Form.Row>
        {fields.map((item, index) => {
          return (
            <Form.Row key={item.id} className='mt-1'>
              <Col>
                <Form.Control
                    className={errorExists(errors, 'ingredients', index, 'name') ? 'is-invalid' : ''}
                    name={`ingredients[${index}].name`}
                    ref={register({
                        required: true
                    })}
                    size="sm"
                />
              </Col>
              <Col xs='2' xl='1'>
                <Form.Control
                    className={errorExists(errors, 'ingredients', index, 'quantity') ? 'is-invalid' : ''}
                    name={`ingredients[${index}].quantity`}
                    ref={register({
                        required: true
                    })}
                    size="sm"
                />
              </Col>
              <Col xs='2' xl='1'>
                <Form.Control
                    className={errorExists(errors, 'ingredients', index, 'unit') ? 'is-invalid' : ''}
                    name={`ingredients[${index}].unit`}
                    ref={register({
                        required: true
                    })}
                    size="sm"
                />
              </Col>
              <Trash2 style={{cursor:"pointer"}} className='mt-1' onClick={() => remove(index)}>Delete</Trash2>
            </Form.Row>
          );
        })}
        <button type="button" className="mt-2 col-12 btn btn-outline-add btn-sm" size="sm" onClick={() => append({name: '', quantity: '', measurement: ''})}>Add</button>
      </Form.Group>
    )
  }